require('./primitives/a-box');
require('./primitives/a-camera');
require('./primitives/a-circle');
require('./primitives/a-collada-model');
require('./primitives/a-cone');
require('./primitives/a-cursor');
require('./primitives/a-curvedimage');
require('./primitives/a-cylinder');
require('./primitives/a-image');
require('./primitives/a-light');
require('./primitives/a-model');
require('./primitives/a-obj-model');
require('./primitives/a-plane');
require('./primitives/a-ring');
require('./primitives/a-sky');
require('./primitives/a-sphere');
require('./primitives/a-torus');
require('./primitives/a-video');
require('./primitives/a-videosphere');
